import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import emptybuild from '../../empty-build.png';
import colors from '../../theme/colors';
import WithoutStockBanner from './WithoutStockBanner';
import { productHasStock } from './utils';

const useStyles = makeStyles(() => ({
    responsive: {
        maxHeight: '412px',
        objectFit: 'contain',
    },
    label: {
        display: 'inline-block',
        background: colors.yellow,
        position: 'absolute',
        width: 'auto',
        zIndex: 1,
    },
    carouselContainer: {
        '& .carousel .control-dots .dot': {
            background: colors.black,
        },
        '& .carousel .control-next.control-arrow:before': {
            borderLeft: `8px solid ${colors.black}`,
        },
        '& .carousel .control-prev.control-arrow:before': {
            borderRight: `8px solid ${colors.black}`,
        },
        '& .carousel .thumbs': {
            boxSizing: 'border-box',
            padding: '0px',
        },
        '& .carousel .thumbs-wrapper': {
            margin: '20px 0px',
        },
        '& .carousel .thumb ': {
            cursor: 'pointer',
            padding: '0px',
        },
        '& .carousel .thumb img': {
            maxHeight: '56px',
            objectFit: 'contain',
            width: '75px',
        },
    },
}));

const CarouselPrincipal = ({ product }) => {
    const { images, label } = product;
    const classes = useStyles();
    return (
        <Box>
            {label && <Box mt={2} px={2} py={1} className={classes.label}>{label}</Box>}
            <Carousel
                showStatus={false}
                showArrows={false}
                className={classes.carouselContainer}
            >
                {
                    (images && images[0])
                        ? images.map((img, index) => {
                            const keyID = `${index}imgCarouselPrincipal`;
                            return (
                                <>
                                    { !productHasStock(product) && <WithoutStockBanner sxContainer={{ position: 'absolute' }} /> }
                                    <img
                                        key={keyID}
                                        alt=""
                                        src={img.url}
                                        className={classes.responsive}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = emptybuild;
                                        }}
                                    />
                                </>
                            );
                        })
                        : (
                            <>
                                { !productHasStock(product) && <WithoutStockBanner sxContainer={{ position: 'absolute' }} /> }
                                <img key="uniqueImgCarouselPrincipal" alt="" src={emptybuild} className={classes.responsive} />
                            </>
                        )
                }
            </Carousel>
        </Box>
    );
};

export default CarouselPrincipal;
